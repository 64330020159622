#avatar {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    object-fit: cover;
}

#profile-head {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8f9fa+0,e5e5e5+100 */
    background: #f8f9fa;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f8f9fa 0%, #e5e5e5 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f8f9fa 0%, #e5e5e5 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f8f9fa 0%, #e5e5e5 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9fa', endColorstr='#e5e5e5', GradientType=0);
    /* IE6-9 */
    margin-bottom: 30px;
}

.profile-row {
    padding: 12px;
    justify-content: center;
}

#aside {
    border-right: 1px solid #8080804a;
    margin-top: 25px;
}

#aside button {
    width: 150px;
    margin-bottom: 10px;
}

.custom-file-upload {
    cursor: pointer;
}

#edit-profile-icons {
    text-align: center;
    font-size: 24pt;
    margin-top: -14px;
}

@media (min-width: 100px) {
    .col-sm-12 {
        text-align: center;
    }
    #avatar {
        margin-bottom: 20px;
    }
}

@media (min-width: 840px) {
    .profile-row, .col-md-3 {
        text-align: left;
    }
}