#categories {
  text-align: center;
  padding: 3%;
  
}
#categories > h1 {
  font-family: "Helvetica Neue";
  

  margin-bottom: 7%;
}


#dropdown-basic{
  height:58px;
  width: 78px;
  margin-bottom: 7px;
}

#close-image {
  display: inline-block;
  height: 134px;
  padding: 0;
  margin: 0;
  vertical-align: top;
  width: 104px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 50px;
  margin: 20px 10px;
  height: 100px;
  font-size: 1.2rem;
  font-weight: bold;
  
  color: rgb(255, 255, 255);
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 7px 7px 5px #ccc;
  text-shadow: -1px -1px 0 rgb(0, 0, 0), 
  1px -1px 0 #000, 
  -1px 1px 0 #000, 
  1px 1px 0 #000;
}
#close-image:hover {
  font-size: 1.3rem;
  transition: all 0.2s ease-in-out;
  transform: scale(1.2);
  
}
