.heading {
    text-align: center;
    font-family: "Helvetica Neue";
    padding-top: 1%;
    padding-bottom: 3%;
}

.btnForm {
    margin-top: 2%;
}

form > button {
    margin-top: 2%;
}
.col-lg-12{
    margin-bottom: 2%;
}
.spinner-border {
    width: 1rem;
    height: 1rem;
    margin-left: 9px;
    margin-bottom: 2px;
}

