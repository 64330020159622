#simpleSider {
    background-image: url('https://res.cloudinary.com/dd5irg4pr/image/upload/v1669128876/background_jobpag.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 135px;
    margin-bottom: 2%;
    text-align: center;
    padding-top: 30px;
    font-family: serif;
}